import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
<path d="M3647 7629 c-120 -28 -257 -150 -326 -290 -82 -168 -76 -36 -76
-1654 l0 -1440 22 -77 c52 -177 160 -318 300 -389 63 -32 73 -34 167 -34 95 0
123 6 481 98 539 140 661 171 1195 306 1301 330 1270 322 1389 387 178 97 279
218 316 380 14 56 15 170 13 802 -3 736 -3 737 -26 796 -67 178 -213 305 -448
389 -38 14 -302 84 -585 156 -282 72 -653 166 -824 210 -517 132 -967 247
-1219 310 -240 61 -299 69 -379 50z m2065 -828 c122 -25 250 -65 391 -123 l97
-40 0 -244 c0 -134 -2 -244 -4 -244 -1 0 -46 20 -99 45 -288 134 -585 195
-812 166 -356 -46 -560 -257 -590 -611 -19 -226 64 -458 208 -585 68 -60 193
-121 287 -141 135 -28 345 -10 465 40 l36 15 -3 133 -3 133 -152 3 -153 3 0
209 0 210 435 0 435 0 -2 -467 -3 -467 -40 -28 c-99 -70 -227 -134 -380 -191
-130 -48 -406 -83 -578 -72 -391 23 -685 166 -889 430 -252 324 -307 797 -141
1195 32 78 41 90 58 86 11 -2 40 -10 65 -16 237 -61 450 193 355 422 -6 15 5
23 56 46 124 56 249 90 424 116 17 2 120 3 230 1 157 -2 223 -7 307 -24z"/>
<path d="M5738 7303 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6138 7173 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6880 6953 c8 -3 24 -10 35 -15 20 -9 20 -9 1 6 -11 9 -27 16 -35 15
-14 0 -14 -1 -1 -6z"/>
<path d="M7010 6883 c1 -9 80 -66 85 -61 2 2 -17 18 -41 36 -24 18 -44 29 -44
25z"/>
<path d="M7090 6861 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M7133 6768 c24 -33 32 -36 17 -8 -6 11 -9 24 -6 28 2 4 -4 8 -13 8
-17 0 -17 -2 2 -28z"/>
<path d="M7160 6781 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M7177 6724 c-11 -11 3 -24 18 -18 8 4 12 10 9 15 -6 11 -18 12 -27 3z"/>
<path d="M7531 6685 c-5 -89 -21 -157 -21 -92 0 27 -1 27 -66 27 -62 0 -104
-17 -86 -34 3 -4 -1 -4 -11 0 -9 3 -15 9 -13 13 3 4 1 13 -5 21 -6 11 -9 2 -9
-27 0 -24 5 -43 11 -43 5 0 7 -4 4 -10 -9 -15 2 -12 19 6 15 14 16 13 16 -6 0
-12 -4 -18 -10 -15 -6 4 -10 -5 -10 -19 0 -16 -6 -26 -15 -26 -9 0 -15 10 -15
26 0 14 -4 23 -9 19 -5 -3 -13 0 -17 6 -4 8 -3 9 4 5 16 -10 14 5 -3 28 -15
20 -18 17 -28 -29 -4 -17 -5 -16 -7 5 -1 14 -10 43 -21 65 -11 22 -17 31 -14
20 40 -131 38 -96 36 -925 -3 -874 -2 -868 -64 -984 -35 -67 -148 -188 -212
-228 -88 -55 -141 -80 -270 -127 -16 -6 -163 -44 -325 -85 -624 -158 -750
-191 -743 -193 5 -1 184 -47 398 -102 215 -55 471 -121 570 -146 99 -25 223
-58 275 -72 122 -33 223 -34 300 -4 117 45 228 156 295 295 67 140 65 93 64
1206 -1 871 -10 1553 -18 1425z m-21 -195 c0 -6 -30 -10 -70 -10 -40 0 -70 4
-70 10 0 6 30 10 70 10 40 0 70 -4 70 -10z"/>
<path d="M7256 6610 c8 -43 14 -51 14 -19 0 13 -5 31 -10 39 -7 11 -8 5 -4
-20z"/>
<path d="M3815 3501 c-57 -25 -79 -63 -79 -132 -1 -70 21 -104 81 -131 48 -21
108 -21 166 1 26 10 27 14 27 76 l0 65 -55 0 c-52 0 -55 -1 -55 -25 0 -18 5
-25 20 -25 14 0 20 -7 20 -24 0 -45 -68 -50 -111 -7 -29 30 -37 67 -23 119 13
47 92 65 159 38 l35 -15 0 29 c0 25 -5 30 -37 39 -55 16 -103 13 -148 -8z"/>
<path d="M5203 3505 c-87 -37 -62 -130 46 -169 43 -15 52 -22 49 -40 -4 -27
-55 -33 -97 -12 -40 21 -41 21 -41 -14 0 -25 5 -30 38 -39 55 -16 93 -13 129
9 37 22 49 56 36 97 -6 21 -25 36 -73 60 -44 22 -65 38 -65 50 0 24 51 29 99
9 l36 -15 0 29 c0 25 -5 30 -37 39 -50 14 -81 13 -120 -4z"/>
<path d="M6200 3497 c-55 -29 -83 -77 -77 -133 6 -62 28 -95 77 -122 38 -20
54 -23 101 -18 78 8 89 13 89 47 0 34 2 33 -43 14 -43 -18 -96 -19 -118 -2
-46 35 -52 110 -13 156 22 26 33 31 70 31 25 0 58 -7 74 -15 30 -16 30 -15 30
14 0 26 -5 31 -37 40 -59 17 -106 13 -153 -12z"/>
<path d="M6495 3501 c-120 -54 -108 -229 19 -271 153 -50 273 131 160 244 -49
49 -111 58 -179 27z m111 -39 c24 -16 44 -59 44 -92 0 -33 -20 -76 -44 -92 -6
-4 -24 -8 -41 -8 -35 0 -45 6 -68 40 -29 42 -13 138 25 153 20 8 72 8 84 -1z"/>
<path d="M4060 3365 l0 -145 100 0 100 0 0 25 c0 24 -2 25 -65 25 l-65 0 0 40
0 40 60 0 c57 0 60 1 60 25 0 24 -3 25 -60 25 l-60 0 0 30 0 30 65 0 c63 0 65
1 65 25 l0 25 -100 0 -100 0 0 -145z"/>
<path d="M4270 3485 c0 -23 3 -25 50 -25 l50 0 0 -120 0 -120 35 0 35 0 0 120
0 120 50 0 c47 0 50 2 50 25 l0 25 -135 0 -135 0 0 -25z"/>
<path d="M4570 3365 l0 -145 35 0 35 0 0 93 0 92 42 -49 42 -49 43 49 43 48 0
-92 0 -92 35 0 35 0 0 145 0 145 -33 0 c-27 0 -39 -8 -76 -57 l-45 -56 -43 49
c-23 27 -43 52 -43 57 0 4 -16 7 -35 7 l-35 0 0 -145z"/>
<path d="M4930 3365 l0 -145 100 0 100 0 0 25 c0 24 -2 25 -65 25 l-65 0 0 40
0 40 60 0 c57 0 60 1 60 25 0 24 -3 25 -60 25 l-60 0 0 30 0 30 65 0 c63 0 65
1 65 25 l0 25 -100 0 -100 0 0 -145z"/>
<path d="M5420 3416 c0 -51 5 -106 10 -122 34 -93 208 -98 246 -8 9 21 14 70
14 129 l0 95 -35 0 -35 0 0 -103 c0 -116 -10 -137 -67 -137 -52 0 -63 24 -63
138 l0 102 -35 0 -35 0 0 -94z"/>
<path d="M5740 3364 l0 -146 96 4 c92 3 97 4 121 32 20 24 24 36 19 65 -3 20
-14 42 -25 49 -19 13 -19 16 -5 38 41 61 -16 104 -136 104 l-70 0 0 -146z
m145 66 c0 -22 -5 -25 -37 -28 -36 -3 -38 -2 -38 28 0 30 2 31 38 28 32 -3 37
-6 37 -28z m13 -92 c17 -17 15 -55 -4 -62 -9 -3 -31 -6 -50 -6 -33 0 -34 1
-34 40 0 40 0 40 38 40 21 0 43 -5 50 -12z"/>
<path d="M6760 3365 l0 -145 35 0 35 0 0 93 1 92 21 -25 c11 -14 29 -36 40
-50 l20 -25 44 50 44 49 0 -92 0 -92 35 0 35 0 0 145 0 145 -35 0 c-19 0 -35
-3 -35 -7 -1 -5 -20 -30 -44 -56 l-43 -48 -44 55 c-37 46 -49 55 -76 56 l-33
0 0 -145z"/>
<path d="M6020 3276 c-27 -33 18 -72 48 -42 13 13 14 20 6 34 -15 23 -38 26
-54 8z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
